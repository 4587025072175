import React, { useState, useEffect } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import {db} from '../../firebase'


import ReactWhatsapp from "react-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import Header from '../inc/Header';

import { 
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
     FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,
  
     LinkedinShareButton,
     LinkedinIcon,
     TwitterIcon,
  }  from "react-share";


function Dimondring () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(50);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [ring, setRing] = useState([])
      const navigate = useNavigate()
  
      const ringCollectionRef = collection(db, "Dimond-Ring");
      useEffect(() => {
  
          const getRing = async () => {
              const data = await getDocs(ringCollectionRef);
              setRing(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getRing()
      }, [])
    return (
      
<>
<Header/>

<br/>
<ScrollToTop smooth top="100" color="maroon" />

<br/>
<br/>
<br/>
     
<div className='ring'>
                <div className='album py-1'>
                    <div className="container mt-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li  className="breadcrumb-item"><Link style={{color:"darkBlue"}} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/diamond" style={{color:"darkBlue"}} >Back</Link></li>
                                <li className="breadcrumb-item"><Link to="/contact" style={{color:"darkBlue"}} >Contact Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Diamond Ring </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

<div className="album py-3">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {ring.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.name.toLowerCase().includes(searchTerm.toLowerCase()) 
       
      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((sghjewellers) => { return (
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 ">
                        <img  className="card-img-top" src={sghjewellers.img}  alt="diamond ring"/>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{sghjewellers.name}</b></h6>

                                <p className="card-text mb-2">{sghjewellers.des}</p>

                                <div className="d-flex justify-content-between align-items-center">
                              
                              <a href='tel: 03472-254990' className="krishnagarbutton mb-3">Call Book</a>
                              <ReactWhatsapp number="+91 9434500354" className="krishnagarbutton mb-3" message="I want to buy this shirt, contact me"> Whatsapp Book</ReactWhatsapp>
                          
                           </div>
                           <button type="button" className="buttonshare me-1">
                                        Share  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>  < FacebookShareButton url="https://www.sghjewellers.com/Diamondring" className="me-2" quote={"sgh-jewellers"} hastag={"#sghjewellers"}
>
    <FacebookIcon size={30} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.sghjewellers.com/Diamondring" className="me-2" quote={"sgh-jewellers"} hastag={"#sghjewellers"}
>
    <WhatsappIcon size={30} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.sghjewellers.com/Diamondring" className="me-2" quote={"sgh-jewellers"} hastag={"#sghjewellers"}
>
    <TwitterIcon size={30} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.sghjewellers.com/Diamondring" className="me-2" quote={"sgh-jewellers"} hastag={"#sghjewellers"}
>
    <  LinkedinIcon size={30} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.sghjewellers.com/Diamondring" quote={"sgh-jewellers"} hastag={"#sghjewellers"}
>
 <  EmailIcon size={30} round={true}/>
</ EmailShareButton>
                     
                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={dul.length}
                /> */}


                            </div>
                            </div>

</>
        
        );
    }
    
    export default Dimondring;



