
import React from 'react'
import logosghjewellers from "../images/sghlogo.jpg";
import { Link } from "react-router-dom";
// import Youtube from "../images/youtube.jpg";


import "./Header.css";

function Header() {
  return (
    <div>
      <div className="fixed-top">
        <div className='sghjewellers'>
          <div className="album py-1">
            <div className="container">


              <a className="mb-3" style={{ color: "white" }} href='tel: 03472-254990'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-plus" viewBox="0 0 16 16">
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                <path fill-rule="evenodd" d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5" />
              </svg> 03472-254990 /</a>

              <a style={{ color: "white" }} href='tel: +91 9434500354'> +91 94345 00354</a>


            </div>
          </div>

        </div>

        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow">
          <div className="container-fluid">
            <Link to="/">
              <img src={logosghjewellers} className="img-fluid me-5" width="100" height="27" alt="logosghjewellers" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>HOME</Link>
                </li>
                <li className="nav-item">
                  <Link to="/goldrate" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>GOLD RATE</Link>
                </li>
                <li className="nav-item">
                  <Link to="/diamond" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>DIAMOND</Link>
                </li>
                <li className="nav-item">
                  <Link to="/gold" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>GOLD</Link>
                </li>
                <li className="nav-item">
                  <Link to="/silver" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>SILVER</Link>
                </li>

                <li className="nav-item">
                  <Link to="/gemstone" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>GEMSTONE</Link>
                </li>
                <li className="nav-item">
                  <a href='https://www.google.co.in/maps/place/SGH+JEWELLERS/@23.4131013,88.4927569,15z/data=!4m8!3m7!1s0x39f92030db55de33:0xd1ea20d44d3af9f5!8m2!3d23.4131013!4d88.4927569!9m1!1b1!16s%2Fg%2F11krpt8pvk?entry=ttu' target="_blank" rel="noopener noreferrer" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>REVIEWS</a>
                </li>

                <li className="nav-item">
                  <a href='https://www.google.co.in/maps/dir//Shona+Porti,+Krishnanagar,+West+Bengal+741101/@23.4130797,88.4103553,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39f92030db55de33:0xd1ea20d44d3af9f5!2m2!1d88.4927569!2d23.4131013?entry=ttu' target="_blank" rel="noopener noreferrer" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>DIRECTION</a>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link active" aria-current="page" style={{ color: "maroon" }}>CONTACT</Link>
                </li>
              </ul>


              {/* <button className="btn btn-outline-success me-3" type="submit"><div align='end'><a href='https:/www.mrindiancoder.com/'>
                              <img src='https://www.free-website-hit-counter.com/c.php?d=9&id=148623&s=55' border='0'
                               alt='Free Website Hit Counter'/></a><br / ><small>
                               </small></div> </button> */}

              {/* <div align='end'><a href='https:/www.mrindiancoder.com/'>
                              <img src='https://www.free-website-hit-counter.com/c.php?d=9&id=148623&s=55' border='0'
                               alt='Free Website Hit Counter'/></a><br / ><small>
                               </small></div> */}


            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Header
