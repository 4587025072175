
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyCmhllQre4baLcMZWr59gtCqrguELzLFTU",
    authDomain: "sgh-jewellers.firebaseapp.com",
    projectId: "sgh-jewellers",
    storageBucket: "sgh-jewellers.appspot.com",
    messagingSenderId: "673188919887",
    appId: "1:673188919887:web:26d8c673f2d78f2e99883a",
    measurementId: "G-JHGXMMM3TC"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;