import React from 'react'

function Gmap() {
  return (
    <div>
        <div className="album py-0">

<div className="container">

  <div className="row">
  <iframe className="iframe-fluid mb-3" height="380" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=SGH%20JEWELLERS,%20Shona%20Porti,%20Krishnanagar,%20West%20Bengal%20741101%20+(SGH%20JEWELLERS,%20Shona%20Porti,%20Krishnanagar,%20West%20Bengal%20741101)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 
  <iframe className="iframe-fluid mb-1" src="https://www.google.com/maps/embed?pb=!4v1711552536582!6m8!1m7!1sLPLu06vVfwFXdb1xzprlag!2m2!1d23.41307404693506!2d88.49280516297122!3f334.9957762891295!4f-0.18701482557226257!5f0.7820865974627469"  height="380"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

  </div>
</div>
</div>
    </div>
  )
}

export default Gmap