import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Dimond from "../images/Dimon.jpg"
import Gold from "../images/Gold.jpg";
import Stone from "../images/Stone.jpg";

import Silver from "../images/Silver.jpg"
// import Breclet from "../images/Kids Wear.jpg";

// import Locket from "../images/locket.jpg";



import "./Item.css";

import { Link } from 'react-router-dom';


function Item() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };

    return (
        <div>


            <div className="animat">
                <Slider {...settings}>
                    <div>

                        <Link to="/diamond">
                            <img src={Dimond} className="card-img-top mb-2" alt="dimondkrishnagar" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "maroon" }} className="card-text mb-0">Diamond</p>
                                <Link to="/diamond">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </center>
                        </div>
                    </div>

                    <div>



                        <Link to="/gold">
                            <img src={Gold} className="card-img-top mb-2" alt="Gold" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "maroon" }} className="card-text mb-0">Gold</p>
                                <Link to="/gold">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </center>
                        </div>
                    </div>


                    <div>


                        <Link to="/silver">
                            <img src={Silver} className="card-img-top mb-2" alt="silver" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "maroon" }} className="card-text mb-0">Silver</p>
                                <Link to="/silver">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </center>
                        </div>

                    </div>

                    <div>


                        <Link to="/gemstone">
                            <img src={Stone} className="card-img-top mb-2" alt="Stone" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "maroon" }} className="card-text mb-0">Stone</p>
                                <Link to="/gemstone">
                                    <p style={{ color: "grey" }} className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </center>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div>


                    </div>


                </Slider>
            </div>

        </div>
    )
}



export default Item
