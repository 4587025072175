import React from 'react'

function Gmap() {
  return (
    <div>
        <div className="album py-0">

<div className="container">

  <div className="row">
  <iframe className="iframe-fluid mb-3" height="110" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=SGH%20JEWELLERS,%20Shona%20Porti,%20Krishnanagar,%20West%20Bengal%20741101%20+(SGH%20JEWELLERS,%20Shona%20Porti,%20Krishnanagar,%20West%20Bengal%20741101)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 

  </div>
</div>
</div>
    </div>
  )
}

export default Gmap