import React from 'react'
import "./Home.css";
import Goldprice from './Goldprice';

function Goldrate2() {
    return (
        <div>
       
       
            <div className='back'>
                <div className="album py-2 ">
                    <div className="container ">
                        <center>
                            <h5 className='mt-2'>Check Today's Gold Rate at SGH jewellers Stores</h5>
                        </center>
                    </div>
                </div>
            </div>

            <div className='backmain'>
                <br/>
                <div className="album py-2">
                <center>
                    <div className="container ">
                       
                            <div className="card text-dark bg-light mb-3" >
                            <div className='text5'>
                                <div className="card-header">GOLD RATE</div>
                                </div>
                                <div className="card-body">
                                    <div className='text5'>
                                    <h1 className="card-title">SGH Jewellers offer unique gold rate</h1></div>
                                    <h6 className="card-title">Gold Rate Today</h6>
                                    <br/>
                                    <div className='text5'>
                                    <h5 className="card-title"> 22 Carat Hallmark (HUID) Gold </h5>
                                    </div>
                                 <center>
                                  <Goldprice/>
                                  </center>
                                </div>
                            </div>
                          
                    </div>
                    </center>
                </div>
                <br/>
            <br/>
            </div>
            
        </div>
    )
}

export default Goldrate2