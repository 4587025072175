
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Gold from './components/pages/Gold';
import Goldrate from './components/pages/Goldrate';
import Ring from './components/pages/Ring';
import Necklace from './components/pages/Necklace';
import Sankha from './components/pages/Sankha';
import Bala from './components/pages/Bala';
import Pendants from './components/pages/Pendants';
import Jhumka from './components/pages/Jhumka';
import Contact from './components/pages/Contact';
import Dimond from './components/pages/Dimond';
import Dimondring from './components/pages/Dimondring';
import Dimondnecklace from './components/pages/Dimondnecklace';
import Dimondbala from './components/pages/Dimondbala';
import Dimondpendants from './components/pages/Dimondpendants';
import Dimondbracelets from './components/pages/Dimondbracelets';
import Silver from './components/pages/Sliver';
import Gemstone from './components/pages/Gemstone';

function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>


      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/gold" element={<Gold/>}/>
      <Route axact path="/goldrate" element={<Goldrate/>}/>
      <Route axact path="/ring" element={<Ring/>}/>
      <Route axact path="/Necklace" element={<Necklace/>}/>
      <Route axact path="/Sankha" element={<Sankha/>}/>
      <Route axact path="/Lohabadhano" element={<Bala/>}/>
      <Route axact path="/Pendants" element={<Pendants/>}/>
      <Route axact path="/Jhumka" element={<Jhumka/>}/>
      <Route axact path="/contact" element={<Contact/>}/>
      <Route axact path="/diamond" element={<Dimond/>}/>
      <Route axact path="/Diamondring" element={<Dimondring/>}/>
      <Route axact path="/Diamondpendants-page" element={<Dimondnecklace/>}/>
      <Route axact path="/Diamondbracelet" element={<Dimondbala/>}/>
      <Route axact path="/Diamondpendants" element={<Dimondpendants/>}/>
      <Route axact path="/Diamondbracelets" element={<Dimondbracelets/>}/>
      <Route axact path="/silver" element={<Silver/>}/>
      <Route axact path="/gemstone" element={<Gemstone/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
