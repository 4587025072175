import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'






function Goldprice() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(9);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [goldrate, setGoldrate] = useState([])
    const navigate = useNavigate()

    const goldrateCollectionRef = collection(db, "GoldRate");
    useEffect(() => {

        const getGoldrate = async () => {
            const data = await getDocs(goldrateCollectionRef);
            setGoldrate(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getGoldrate()
    }, [])
    return (
      
<>



            <div className="album py-1">

                <div className="container">

                    <div className="row">
                        {goldrate.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            }
                            {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((sghjewellers) => {
                            return (
                                <center>
                                    <div className="col-md-4 mt-8" >

                                        <div className="card-body">
                                        <div className='text5'>
                                            <h5 className="card-text mb-2"> <b> ₹{sghjewellers.rate} INR (Per gm)</b></h5>
                                            </div>
                                            <p style={{ color: "Darkblue" }} className="card-text">{sghjewellers.date} </p>



                                        </div>

                                    </div>
                                    </center>
                                )})}
                                </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={dul.length}
                /> */}


                            </div>
                </div>

            </>

            );
    }

            export default Goldprice;



