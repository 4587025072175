import React from 'react'
import Header from '../inc/Header'
import Ring from "../images/Ring-jewellers.jpg"

import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import Necklacejewellerskrishnagar from "../images/necklace-jewellers.jpg"
import Sankha from "../images/sankha-jewellers.jpg";
import Pendants from "../images/Pendants.jpg";
import Bala from"../images/Bala-Jewellers.jpg";
import Jhumka from "../images/Jhumka_jewellers.jpg";

import "./Home.css"
// import Kidsslider from '../inc/Kidsslider';

function Gold() {
    return (
        <div>
            <Header/>
            <br />
            <br />
            <br />
          <br/>
            
     
            <div className='ring'>
                <div className='album py-1'>
                    <div className="container mt-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li  className="breadcrumb-item"><Link style={{color:"darkBlue"}} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/contact" style={{color:"darkBlue"}} >Contact Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Gold </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <Kidsslider/> */}
            <ScrollToTop smooth top="100" color="maroon" />
            <div className='album py-2'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mt-3">
                            <div className="card shadow" >
                                <Link to="/ring">
                                    <img src={Ring} className="card-img-top" alt="Ring-Jewellers"/>
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkblue" }} className="card-title">Ring</h5>
                                            <p style={{ color: "grey" }} className="card-text">All Type Ring Design & Color Available at SGH Jewellers</p>
                                            <Link to="/ring" className="appbuttonmanmohinicall">Learn More</Link>
                                        </center>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card shadow" >
                                <Link to="/Necklace">
                                    <img src={Necklacejewellerskrishnagar} className="card-img-top" alt="Ring-Jewellers Krishnagar"/>
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkblue" }} className="card-title">Necklace</h5>
                                            <p style={{ color: "grey" }} className="card-text">Necklace Design Trending at SGH Jewellers</p>
                                            <Link to="/Necklace" className="appbuttonmanmohinicall">Learn More</Link>
                                        </center>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card shadow" >
                                <Link to="/Sankha">
                                    <img src={Sankha} className="card-img-top" alt="Sankha-Jewellers Krishnagar"/>
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkblue" }} className="card-title">Sankha</h5>
                                            <p style={{ color: "grey" }} className="card-text">Sankha Design Trending at SGH Jewellers</p>
                                            <Link to="/Sankha" className="appbuttonmanmohinicall">Learn More</Link>
                                        </center>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card shadow" >
                                <Link to="/Lohabadhano">
                                    <img src={Bala} className="card-img-top" alt="Bala-Jewellers Krishnagar"/>
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkblue" }} className="card-title">Loha Badhano</h5>
                                            <p style={{ color: "grey" }} className="card-text">Best Loha badhano Design at SGH jewellers</p>
                                            <Link to="/Lohabadhano" className="appbuttonmanmohinicall">Learn More</Link>
                                        </center>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card shadow" >
                                <Link to="/Pendants">
                                    <img src={Pendants} className="card-img-top" alt="Pendants-Jewellers Krishnagar"/>
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkblue" }} className="card-title">Pendants</h5>
                                            <p style={{ color: "grey" }} className="card-text">Pendants Design Trending at SGH Jewellers</p>
                                            <Link to="/Pendants" className="appbuttonmanmohinicall">Learn More</Link>
                                        </center>
                                    </div>
                                </Link>
                            </div>
                        </div>
             
                        <div className="col-md-3 mt-3">
                            <div className="card shadow" >
                                <Link to="/Jhumka">
                                    <img src={Jhumka} className="card-img-top" alt="Jhumka-Jewellers Krishnagar"/>
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkblue" }} className="card-title">Jhumka</h5>
                                            <p style={{ color: "grey" }} className="card-text">Any Type of Jhumka Available at SGH Jewellers</p>
                                            <Link to="/Jhumka" className="appbuttonmanmohinicall">Learn More</Link>
                                        </center>
                                    </div>
                                </Link>
                            </div>
                        </div>
                       
                       

                    </div>
                </div>
            </div>
            <br/>
            
        </div>
    )
}

export default Gold