import React from 'react';
// import './Footer.css';
import { Link } from 'react-router-dom';

// import logo from "../images/logojewellers.jpg";
import MRINDIANCODER from "../images/mr.indiancoder.jpg";
import "./Footer.css";
import ReactWhatsapp from "react-whatsapp";
import Gmap from '../pages/Gmap';
function Footer() {
  return (


    <div className="jewellerskrishnagar">
      <br></br>
      <br />
      {/* <div className="p-3 mb-0 bg-dark text-maroon"> */}

      <div className="album py-3">

        <div className="container">

          <div className="row">
            <div className="col-md-3 ">

              <h6 style={{ color: "maroon" }}>Showroom Address</h6>
              <hr />

              <ul className="list-unstyled">

                <h6 style={{ color: "maroon" }} className="card-text "> <b>SGH JEWELLERS</b></h6>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      ADDRESS DETAILS
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p style={{ color: "maroon" }} className="card-text mb-1">Baranasi Roy Road, Sonapati, Krishnanagar</p>
                        <p style={{ color: "maroon" }} className="card-text">  Nadia, West Bengal, 741101, India</p>
                       
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        CONTACT DETAILS
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                   
               

                        <a className="appbuttonmanmohinicall me-1" href="tel: 03472-254990"> 03472-254990</a>
                        <br/>
                        <a className="appbuttonmanmohinicall  mb-1" href="tel: 9434500354">+91 94345 00354 </a>
                      </div>
                    </div>
                  </div>
                  
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        DIRECTION
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">

                        <Gmap/>
                           
                              </div>
                     
                    </div>
                  </div>
                
                </div>

               
              

              </ul>
            </div>

            <div className="col-md-3">
              <h6 style={{ color: "maroon" }}>Product</h6>
              <hr />
              <ul className="list-unstyled">




                <Link style={{ color: "maroon" }} to="/diamond">
                  <li style={{ color: "maroon" }} className="mb-2"><a ><small>DIAMOND</small></a></li></Link>
                <Link style={{ color: "maroon" }} to="/gold">
                  <li style={{ color: "maroon" }} className="mb-2"><a ><small> GOLD</small></a></li></Link>
                <Link style={{ color: "maroon" }} to="/silver">
                  <li style={{ color: "maroon" }} className="mb-2"><a><small>SILVER</small></a></li></Link>
                  <Link style={{ color: "maroon" }} to="/gemstone">
                  <li style={{ color: "maroon" }} className="mb-2"><a><small>GEMSTONE</small></a></li></Link>
   




              </ul>

            </div>
            <div className="col-md-3">

              <h6 style={{ color: "maroon" }}>Quick Link</h6>
              <hr />

              <ul class="list-unstyled">

                <Link style={{ color: "maroon" }} to="/contact">
                  <li style={{ color: "maroon" }} className="mb-2"><small>CONTACT </small></li></Link>

              </ul>

              <br/>
              <br/>
              <h6 style={{ color: "maroon" }}>Legal </h6>
              <hr />
              <ul class="list-unstyled">
              <Link style={{ color: "maroon" }} to="#">
                  <li style={{ color: "maroon" }} className="mb-2"><small>Terms & Condition</small></li></Link>
                  <Link style={{ color: "maroon" }} to="#">
                  <li style={{ color: "maroon" }} className="mb-2"><small>Privacy Policy </small></li></Link>
                  <Link style={{ color: "maroon" }} to="#">
                  <li style={{ color: "maroon" }} className="mb-2"><small>Shipping Policy </small></li></Link>
                  </ul>

            </div>
            <div className="col-md-3">
              <h6 style={{ color: "maroon" }}>Social Link</h6>
              <hr />
              <ul className="list-unstyled">
                <li className="mb-2"> <a style={{ color: "blue" }} href="https://www.facebook.com/sghjewellers/" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "blue" }} className="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg> <small> Facebook</small></a> </li>

                <li className="mb-2"> <a style={{ color: "red" }} href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "red" }} className="bi bi-youtube" viewBox="0 0 16 16">
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                  </svg> <small> Youtube</small></a> </li>






              </ul>

            </div>





          </div>
        </div>
      </div>

      <center>
                 <button className="btn btn-light"> <small> Visitor Counter </small> <a href="https://www.sghjewellers.com" target="_blank">
<img src="https://hitwebcounter.com/counter/counter.php?page=13130569&style=0011&nbdigits=9&type=page&initCount=160" title="SGH Jewellers" Alt="sgh-jewellers"   border="0" /></a>         
 </button>
 </center> 
    
      <div className="album py-3">

        <div className="container">

          <div className="row">
            <div className="col-md-4">
             
       
            </div>

            <div className="col-md-4">
              <p style={{ color: "maroon" }}> Copyright © 2024, SGH JEWELLERS</p>

            </div>
            <div className="col-md-4">
                 <small style={{color:"maroon"}}> Development & Design By: <a style={{color:"maroon"}} href="https://www.mrindiancoder.com/" target="_blank"  rel="noopener noreferrer"> MR. INDIAN CODER</a> </small>
                <small>  <a  href="https://www.mrindiancoder.com/" target="_blank"  rel="noopener noreferrer"> </a> </small>
                <br/>
                <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> <img src={MRINDIANCODER} width="60" height="22" alt="mr.indiancoder"/> </a>
                 </div>

          </div>
        </div>
      </div>

    </div>
    //  </div>





  );
}
export default Footer;